
<template>
  <div>
   
  <div class="section wf-section">
    <div class="div-block-19">
      <div class="div-block-20">
        <h1 class="heading-4">SOSCLICK</h1>
        <h1 class="heading-5">Botón</h1>
        <a href="#" class="lightbox-link-2 w-inline-block w-lightbox">
          <div class="text-block-4">Ver Video</div>
          <script type="application/json" class="w-json">{
  "items": [
    {
      "url": "https://www.youtube.com/watch?v=v9KkV2CHxYU",
      "originalUrl": "https://www.youtube.com/watch?v=v9KkV2CHxYU",
      "width": 940,
      "height": 528,
      "thumbnailUrl": "https://i.ytimg.com/vi/v9KkV2CHxYU/hqdefault.jpg",
      "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fv9KkV2CHxYU%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dv9KkV2CHxYU&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fv9KkV2CHxYU%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
      "type": "video"
    }
  ],
  "group": ""
}</script>
        </a>
      </div>
      <div class="div-block-21"></div>
      <div class="div-block-33">
        <div data-w-id="2fd0aed1-4a21-1b76-c436-a05df6a26989" class="div-block-22">
          <h1 style="-webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:1" class="heading-7">más acompañados.</h1>
          <h1 style="-webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:1" class="heading-7">más conectados</h1>
          <h1 style="-webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:1" class="heading-7">Más seguros</h1>
          <h1 style="-webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:1" class="heading-7">Más Cerca</h1>
        </div>
        <p class="paragraph-3">Las emergencias ocurren en cualquier momento. Protege a tus Adultos Mayores con el mejor y más sencillo sistema de comunicación inmediata, monitoreo y geolocalización en tiempo real.</p>
      </div>
    </div>
    <div class="div-block-18"></div>
  <div class="botones-marketplace">
      <a href="https://ventas.iduam.com/" class="buton-iduam w-inline-block" target="_self"><img src="images/iduam-logo.svg" loading="lazy" alt="" class="image-20">
        <div class="text-block-6">Iduam Marketplace</div>
      </a>
      <a href="https://ventasadultomayor.sosclick.cl/" class="buton-sosclickadultomayor w-inline-block" target="_self"><img src="https://uploads-ssl.webflow.com/62d84efe9abaa639bc5b217b/62e1f69b6fcacb0dcae0a0f3_logo-2.png" loading="lazy" alt="" class="image-20">
        <div class="text-block-6">SOSCLICK Adulto Mayor Marketplace</div>
      </a>
    </div>
    
  </div>
  <section class="hero-heading-left wf-section">
    <div class="containerhtml">
      <div class="hero-wrapper">
        <div class="hero-split">
          <div data-w-id="f83c004b-f8c3-d0d1-da0e-1a837e183de9" style="opacity:0" class="div-block-37">
            <h1 class="heading-10"><strong data-w-id="5afd30a9-8040-d7bd-d1dc-e70399583589" style="opacity:0" class="bold-text-2">SOSCLiCK</strong></h1>
            <h1 class="heading-9">Botón</h1>
            <div class="text-block-5">LA MEJOR SOLUCIÓN PARA EL CUIDADO DE LOS ADULTOS MAYORES</div>
            <p class="margin-bottom-24px">Para el acompañamiento y seguimiento de los Adultos Mayores por parte de sus familiares, el sistema cuenta con una plataforma de asistencia que consiste en una aplicación móvil que deberá instalar en su smartphone el pariente o familiar que hará las veces de administrador y un botón de emergencia que se le entregará al Adulto Mayor de nombre &quot;SosClick Botón&quot;.<br></p>
            <a href="#" class="lightbox-link-2 w-inline-block w-lightbox">
              <div class="text-block-4">Ver Video</div>
              <script type="application/json" class="w-json">{
  "items": [
    {
      "url": "https://www.youtube.com/watch?v=v9KkV2CHxYU",
      "originalUrl": "https://www.youtube.com/watch?v=v9KkV2CHxYU",
      "width": 940,
      "height": 528,
      "thumbnailUrl": "https://i.ytimg.com/vi/v9KkV2CHxYU/hqdefault.jpg",
      "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fv9KkV2CHxYU%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dv9KkV2CHxYU&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fv9KkV2CHxYU%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
      "type": "video"
    }
  ],
  "group": ""
}</script>
            </a>
          </div>
        </div>
        <div class="hero-split _2"><img src="images/Botones-new.png" loading="lazy" style="opacity:0" data-w-id="37c21435-5a75-1ffb-72c2-2eeaada3d0f7" sizes="100vw" srcset="images/Botones-p-500.png 500w, images/Botones-p-800.png 800w, images/Botones-p-1080.png 1080w, images/Botones-p-1600.png 1600w, images/Botones-new.png 1944w" alt="" class="shadow-two"></div>
      </div>
    </div>
  </section>
  <section class="features-list wf-section">
    <div class="containerhtml">
      <div class="features-wrapper-two">
        <div class="features-left">
          <p data-w-id="3413235f-c38d-84f5-877a-3beb7a5a449e" style="opacity:0" class="features-paragraph">Es la nueva solución para el cuidado de tus familiares, somos una plataforma con una serie de características únicas que te ayudaran a estar más cerca de ellos. <br></p>
        </div>
        <ul data-w-id="3413235f-c38d-84f5-877a-3beb7a5a44a4" style="opacity:0" role="list" class="features-right w-list-unstyled">
          <li class="features-block-two"><img src="images/Grupo-3907.svg" loading="lazy" alt="" class="features-image">
            <p><strong class="bold-text-4">Fácil de usar</strong> <br>El dispositivo está especialmente creado para ser sencillo de usar por cualquier usuario que no esté familiarizado con las nuevas tecnologías.</p>
          </li>
          <li class="features-block-two"><img src="images/Grupo-3906.svg" loading="lazy" alt="" class="features-image">
            <p><strong class="bold-text-5">Comunicación inmediata </strong><br>En cualquier momento podremos comunicarnos mediante una simple videoconferencia.</p>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section class="pricing-items5 wf-section" id="planes">
    <h1 class="heading-11">Tenemos una serie de botones para cuidar a tus seres queridos </h1>
    <div data-w-id="b1fa279a-7b77-6126-3497-71737f98ad12" style="opacity:0" class="div-block-39">
      <div id="w-node-b1fa279a-7b77-6126-3497-71737f98ad13-3400ac8a">
        <div class="div-block-40"><img src="images/Icon-material-touch-app.svg" loading="lazy" alt="" class="image-16">
          <h1 class="heading-19">Fácil de Usar</h1>
        </div>
        <p id="w-node-b1fa279a-7b77-6126-3497-71737f98ad16-3400ac8a" class="paragraph-10">Con solo presionar el Botón de SOSClick al costado derecho del Reloj, el usuario generará una alerta, esta será enviada a las personas que hayan asociado su número en la Aplicación, las llamadas se efectuarán desde el primer número al último que se haya configurado, si nadie contesta, nuestro Call Center responderá la llamada para atender al Adulto Mayor.</p>
      </div>
      <div id="w-node-b1fa279a-7b77-6126-3497-71737f98ad18-3400ac8a" class="div-block-46">
        <div class="div-block-41"><img src="images/Icon-map-health.svg" loading="lazy" alt="" class="image-17">
          <h1 id="w-node-b1fa279a-7b77-6126-3497-71737f98ad19-3400ac8a" class="heading-20">Salud</h1>
        </div>
        <p id="w-node-b1fa279a-7b77-6126-3497-71737f98ad1b-3400ac8a" class="paragraph-9">Con este dispositivo podrá realizar controles de la Presión Arterial y Ritmo Cardiaco del Adulto Mayor, además podrá configurar alarmas para que le recuerde tomar sus medicamentos.</p>
      </div>
      <div id="w-node-b1fa279a-7b77-6126-3497-71737f98ad1d-3400ac8a" class="div-block-45">
        <div class="div-block-42"><img src="images/Icon-awesome-map-marked-alt.svg" loading="lazy" alt="" class="image-18">
          <h1 id="w-node-b1fa279a-7b77-6126-3497-71737f98ad1e-3400ac8a" class="heading-21">Geolocalización</h1>
        </div>
        <p id="w-node-b1fa279a-7b77-6126-3497-71737f98ad20-3400ac8a" class="paragraph-8">Desde la Aplicación, podrá conocer en línea la ubicación del Adulto Mayor en cualquier momento, esta ubicación tiene una presición GPS 5M (cielo abierto).</p>
      </div>
      <div id="w-node-b1fa279a-7b77-6126-3497-71737f98ad22-3400ac8a" class="div-block-44">
        <div class="div-block-43"><img src="images/Icon-material-phone-in-talk.svg" loading="lazy" alt="" class="image-19">
          <h1 id="w-node-b1fa279a-7b77-6126-3497-71737f98ad23-3400ac8a" class="heading-22">Comunicación Inmediata</h1>
        </div>
        <p id="w-node-b1fa279a-7b77-6126-3497-71737f98ad25-3400ac8a" class="paragraph-11">El Adulto Mayor, podrá recibir y realizar llamadas a los números que se hayan configurado en la Aplicación además cuenta con un ambiente en el que podrá utilizar el reloj como un teléfono móvil.</p>
      </div>
    </div>
    <div class="containerhtml">
      <div class="pricing-wrapper-two">
        <div class="pricing-card-two">
          <div class="div-block-34"><img src="images/SOS-1.png" loading="lazy" style="opacity:0" data-w-id="7032ef33-3d9e-0ccd-28db-ce590babf42b" sizes="(max-width: 479px) 87vw, (max-width: 767px) 83vw, (max-width: 991px) 24vw, 31vw" srcset="images/SOS-1-p-500.png 500w, images/SOS-1-p-800.png 800w, images/SOS-1.png 956w" alt="" class="pricing-image-two shadow-two"></div>
          <div class="pricing-content">
            <div class="pricing-info">
              <h3 class="heading"><span data-w-id="7a85dc64-c2df-b1ba-3610-68ff0a72c2cf" style="opacity:0" class="text-span">SOSCLICK V28</span><br></h3>
              <!--a href="#" class="button-primary contacto w-button">Contactar vendedor</a-->
              <a href="/compras?plan=1" class="button-primary outline-button w-button">Comprar</a>
            </div>
            <p data-w-id="7032ef33-3d9e-0ccd-28db-ce590babf437" style="opacity:0" class="pricing-details-text">Para el acompañamiento y seguimiento de los Adultos Mayores por parte de sus familiares, el sistema cuenta con una plataforma de asistencia que consiste en una aplicación móvil que deberá instalar en su smartphone el pariente o familiar que hará las veces de administrador y un botón de emergencia que se le entregará al Adulto Mayor de nombre &quot;SosClick Botón&quot;.</p>
            <div class="pricing-divider-two"></div>
            <div class="tagline"><strong data-w-id="d08d8275-94b0-82b2-32ee-b18df8abf7c7" style="opacity:0" class="bold-text">Características </strong></div>
            <div class="div-block-47">
              <div class="pricing-details">
                <div data-w-id="cb83d02b-242c-18e9-3907-baa01813c850" style="opacity:0" class="pricing-feature">GSM 2G 850/900/1800/1900 MHz.</div>
                <div data-w-id="da52050a-e8d1-2c6e-0abc-fedc2e862c28" style="opacity:0" class="pricing-feature">Capacidad de batería: 400 mA.</div>
                <div class="pricing-feature">Seguimiento en tiempo real.</div>
                <div class="pricing-feature">Ruta histórica.</div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">Geolocalización, precisión GPS 10-15M.</div>
                <div class="pricing-feature">Alarma SOS y llamada desde el botón.</div>
                <div class="pricing-feature">Llamadas bidireccionales.</div>
                <!--div class="pricing-feature">Alarma por caídas.</div-->
              </div>
            </div>
          </div>
        </div>
        <div class="pricing-card-two">
          <div class="div-block-35"><img src="images/SOS-2.png" loading="lazy" style="opacity:0" data-w-id="34b1dda8-1fd4-a1cc-dc1a-299aa1fc500a" sizes="(max-width: 479px) 87vw, (max-width: 767px) 83vw, (max-width: 991px) 23vw, 31vw" srcset="images/SOS-2-p-500.png 500w, images/SOS-2-p-800.png 800w, images/SOS-2.png 956w" alt="" class="pricing-image-two shadow-two"></div>
          <div class="pricing-content">
            <div class="pricing-info">
              <h3 class="heading"><span class="text-span-2"><strong data-w-id="9ffd19d5-435a-a563-8c7e-6aa946dfc2d7" style="opacity:0" class="bold-text-3">SOSCLICK Reloj V46</strong></span></h3>
              <!--a href="#" class="button-primary contacto w-button">Contactar vendedor</a-->
              <a href="/compras?plan=2" class="button-primary outline-button w-button">Comprar</a>
            </div>
            <p data-w-id="34b1dda8-1fd4-a1cc-dc1a-299aa1fc5011" style="opacity:0" class="pricing-details-text">Para el acompañamiento y seguimiento de los Adultos Mayores por parte de sus familiares, el sistema cuenta con una plataforma de asistencia que consiste en una aplicación móvil que deberá instalar en su smartphone el pariente o familiar que hará las veces de administrador y un botón de emergencia que se le entregará al Adulto Mayor de nombre &quot;SosClick Botón&quot;.<br></p>
            <div class="pricing-divider-two"></div>
            <div class="tagline"><strong data-w-id="34b1dda8-1fd4-a1cc-dc1a-299aa1fc5015" style="opacity:0" class="bold-text">Características </strong></div>
            <div class="div-block-48">
              <div class="pricing-details">
                <div data-w-id="583baac2-90d8-0d56-3deb-d42b101c3472" style="opacity:0" class="pricing-feature">Pantalla táctil 1,54 pulgadas (240x240 dpi).</div>
                <div data-w-id="583baac2-90d8-0d56-3deb-d42b101c3474" style="opacity:0" class="pricing-feature">GPS, seguimiento en tiempo real.</div>
                <div class="pricing-feature">4G LTE+3G WCDMA +2G GSM Networks.</div>
                <div class="pricing-feature">Medición de Presión Arterial y Ritmo Cardiaco.</div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">Recordatorio de Medicamentos.</div>
                <div class="pricing-feature">Geolocalización, precisión, GPS 5M.</div>
                <div class="pricing-feature">Alarma SOS y llamada desde el Botón.</div>
                <div class="pricing-feature">Llamadas bidireccionales con marcación.</div>
              </div>
            </div>
          </div>
        </div>
        <div class="pricing-card-two">
          <div class="div-block-36"><img src="images/SOS-3.png" loading="lazy" style="opacity:0" data-w-id="b47fe076-323b-0942-ff41-3b1a57810e3d" sizes="(max-width: 479px) 87vw, (max-width: 767px) 83vw, (max-width: 991px) 21vw, 31vw" srcset="images/SOS-3-p-500.png 500w, images/SOS-3-p-800.png 800w, images/SOS-3.png 956w" alt="" class="pricing-image-two shadow-two"></div>
          <div class="pricing-content">
            <div class="pricing-info">
              <h3 class="heading"><span class="text-span-3">SOSCLICK COLGANTE</span></h3>
              <!--a href="#" class="button-primary contacto w-button">Contactar vendedor</a-->
              <a href="/compras?plan=3" class="button-primary outline-button w-button">Comprar</a>
            </div>
            <p class="pricing-details-text">Para el acompañamiento y seguimiento de los Adultos Mayores por parte de sus familiares, el sistema cuenta con una plataforma de asistencia que consiste en una aplicación móvil que deberá instalar en su smartphone el pariente o familiar que hará las veces de administrador y un botón de emergencia que se le entregará al Adulto Mayor de nombre &quot;SosClick Botón&quot;.<br></p>
            <div class="pricing-divider-two"></div>
            <div class="tagline"><strong class="bold-text">Características </strong></div>
            <div class="div-block-38">
              <div class="pricing-details">
                <div class="pricing-feature">4G LTE-3G WCDMA+2G GSM</div>
                <div class="pricing-feature">Impermeable IP67</div>
                <div class="pricing-feature">Posicionamiento GPS+Beidou, precisión hasta 5m</div>
                <div class="pricing-feature">Posicionamiento WIFI, precisión hasta 30m</div>
                <div class="pricing-feature">Plataforma web/Aplicación telefónica para consulta de posiciones</div>
                <!--div class="pricing-feature">Detección de caídas con alerta</div-->
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">SOS llamada urgente de ayuda</div>
                <div class="pricing-feature">Llamada de marcación rápida</div>
                <div class="pricing-feature">Anillo para encontrar el dispositivo</div>
                <div class="pricing-feature">Visualización de ruta histórica / Geo-valla</div>
                <div class="pricing-feature">Alerta de cambio de tarjeta SIM bateria baja</div>
                <div class="pricing-feature">Reloj parlante para narración de voz en tiempo real</div>
              </div>
            </div>
          </div>
        </div>
        <!--div class="pricing-card-two">
          <div class="div-block-36"><img src="images/SOS-4.png" loading="lazy" style="opacity:0" data-w-id="10afc4ca-9ceb-1843-a093-7f6ba5ec34dd" sizes="(max-width: 479px) 87vw, (max-width: 767px) 83vw, (max-width: 991px) 23vw, 31vw" srcset="images/SOS-4-p-500.png 500w, images/SOS-4-p-800.png 800w, images/SOS-4.png 956w" alt="" class="pricing-image-two shadow-two"></div>
          <div class="pricing-content">
            <div class="pricing-info">
              <h3 class="heading"><span class="text-span-3">SOSCLICK V48</span></h3>
              <a href="#" class="button-primary contacto w-button">Contactar vendedor</a>
              <a href="/compras" class="button-primary outline-button w-button">Comprar</a>
            </div>
            <p class="pricing-details-text">Para el acompañamiento y seguimiento de los Adultos Mayores por parte de sus familiares, el sistema cuenta con una plataforma de asistencia que consiste en una aplicación móvil que deberá instalar en su smartphone el pariente o familiar que hará las veces de administrador y un botón de emergencia que se le entregará al Adulto Mayor de nombre &quot;SosClick Botón&quot;.<br></p>
            <div class="pricing-divider-two"></div>
            <div class="tagline"><strong class="bold-text">Características </strong></div>
            <div class="div-block-49">
              <div class="pricing-details">
                <div class="pricing-feature">Redes 4G LTE+2G GSM</div>
                <div class="pricing-feature">Formas de seguimiento GPS + LBS + WIFI + AGPS</div>
                <div class="pricing-feature">Pantalla LCD HD de 1.3&#x27; (240*240pdi)</div>
                <div class="pricing-feature">Llamada de emergencia SOS bidireccional</div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">Cámara HD de 2 megapíxeles para tomar fotografías</div>
                <div class="pricing-feature">Tono de llamada para encontrar el colgante.</div>
                <div class="pricing-feature">Plataforma web/Aplicación telefónica/SMS para consulta de posiciones</div>
                <div class="pricing-feature">Batería: 600 mAh</div>
              </div>
            </div>
          </div>
        </div-->
        <div class="pricing-card-two">
          <div class="div-block-36"><img src="images/SOS-5.png" loading="lazy" style="opacity:0" data-w-id="068456ad-1e23-3d9b-e546-5e33a9a9eceb" sizes="(max-width: 479px) 87vw, (max-width: 767px) 83vw, (max-width: 991px) 23vw, 31vw" srcset="images/SOS-5-p-500.png 500w, images/SOS-5-p-800.png 800w, images/SOS-5.png 956w" alt="" class="pricing-image-two shadow-two"></div>
          <div class="pricing-content">
            <div class="pricing-info">
              <h3 class="heading"><span class="text-span-3">SOSCLICK BAND</span></h3>
              <!--a href="#" class="button-primary contacto w-button">Contactar vendedor</a-->
              <a href="/compras?plan=4" class="button-primary outline-button w-button">Comprar</a>
            </div>
            <p class="pricing-details-text">Para el acompañamiento y seguimiento de los Adultos Mayores por parte de sus familiares, el sistema cuenta con una plataforma de asistencia que consiste en una aplicación móvil que deberá instalar en su smartphone el pariente o familiar que hará las veces de administrador y un botón de emergencia que se le entregará al Adulto Mayor de nombre &quot;SosClick Botón&quot;.<br></p>
            <div class="pricing-divider-two"></div>
            <div class="tagline"><strong class="bold-text">Características </strong></div>
            <div class="div-block-50">
              <div class="pricing-details">
                <div class="pricing-feature">Alarma de batería baja</div>
                <div class="pricing-feature">Tono de llamada para encontrar la pulsera.</div>
                <div class="pricing-feature">Detección de frecuencia cardíaca, presión arterial y temperatura</div>
                <div class="pricing-feature">Recordatorio de pastillas, conteo de pasos y gestión de la salud</div>
                <div class="pricing-feature">Plataforma web/Aplicación móvil/Comandos SMS para ubicación</div>
                <div class="pricing-feature">Batería: 1000 mAh</div>
              </div>
              <div class="pricing-details">
                <div class="pricing-feature">4G LTE + 2G GSM</div>
                <div class="pricing-feature">Formas de seguimiento GPS + LBS + WIFI + AGPS</div>
                <div class="pricing-feature">Nivel impermeable: IPX7</div>
                <div class="pricing-feature">Botón SOS para ayuda</div>
                <div class="pricing-feature">Un clic para una llamada rápida</div>
                <div class="pricing-feature">Reloj parlante, alarma de caída.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="logos-title-large wf-section">
    <div class="containerhtml-3">
      <h2 class="clients-title">Otros Productos de la empresa </h2>
      <div data-w-id="aaf95f6e-92dc-5615-f4d2-fadc858fe285" style="opacity:0" class="clients-wrapper"><img src="images/logo-sosclick.png" loading="lazy" width="77" alt="The Paak logo" class="clients-image"><img src="images/Grupo-43.png" loading="lazy" width="97" alt="Ephicient logo" class="clients-image"><img src="images/Grupo-42.png" loading="lazy" width="153" alt="Arise Health logo" class="clients-image"><img src="images/logo-login.svg" loading="lazy" width="139" alt="OE logo" class="clients-image"><img src="images/logo-comuna.png" loading="lazy" width="165" alt="2020INC logo" class="clients-image"><img src="images/Alo-Doctores.png" loading="lazy" width="185" alt="2020INC logo" class="clients-image"></div>
    </div>
  </section>
  <section class="testimonial-slider-large wf-section">
    <div class="containerhtml-3">
      <div data-delay="4000" data-animation="slide" class="testimonial-slider-two w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="12" data-duration="500" data-infinite="true">
        <div class="mask w-slider-mask">
          <div class="w-slide">
            <div class="testimonial-slide">
              <div class="testimonial-content"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a91e612aae8_quote-mark.svg" loading="lazy" alt="" class="testimonial-quote-icon">
                <div class="testimonial-quote">Cuidar a mis padres para mí siempre es una prioridad y SOSclick me ayuda a lograrlo </div>
                <div class="testimonial-info-two"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg" loading="lazy" alt="" class="testimonial-image">
                  <div>
                    <div class="testimonial-author-name">Diego Lagos </div>
                    <div>Hijo</div>
                  </div>
                </div>
              </div><img src="images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min.jpeg" loading="lazy" srcset="images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-500.jpeg 500w, images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-800.jpeg 800w, images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-1080.jpeg 1080w, images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-1600.jpeg 1600w, images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-2000.jpeg 2000w, images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-2600.jpeg 2600w, images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min-p-3200.jpeg 3200w, images/senior-man-with-daughter-outdoors-2022-03-08-01-34-21-utc-min.jpeg 7360w" sizes="(max-width: 479px) 84vw, (max-width: 767px) 89vw, (max-width: 991px) 21vw, 350px" alt="" class="testimonial-image-two">
            </div>
          </div>
          <div class="w-slide">
            <div class="testimonial-slide">
              <div class="testimonial-content"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a91e612aae8_quote-mark.svg" loading="lazy" alt="" class="testimonial-quote-icon">
                <div class="testimonial-quote">Desde que abuelo falleció siempre me preocupo de que mi abuela se sintiera muy sola, pero con SOSclick ahora puedo estar con ella siempre desde cualquier parte</div>
                <div class="testimonial-info-two"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg" loading="lazy" alt="" class="testimonial-author-image-three">
                  <div>
                    <div class="testimonial-author-name">Maria Victoria Rodriguez</div>
                    <div>Nieta</div>
                  </div>
                </div>
              </div><img src="images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min.jpg" loading="lazy" srcset="images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-500.jpg 500w, images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-800.jpg 800w, images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-1080.jpg 1080w, images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-1600.jpg 1600w, images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-2000.jpg 2000w, images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-2600.jpg 2600w, images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min-p-3200.jpg 3200w, images/happy-senior-woman-with-spectacles-laughing-at-hom-2022-02-01-22-38-30-utc-min.jpg 6192w" sizes="(max-width: 479px) 84vw, (max-width: 767px) 89vw, (max-width: 991px) 21vw, 350px" alt="" class="testimonial-image-two">
            </div>
          </div>
          <div class="w-slide">
            <div class="testimonial-slide">
              <div class="testimonial-content"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a91e612aae8_quote-mark.svg" loading="lazy" alt="" class="testimonial-quote-icon">
                <div class="testimonial-quote">Mi padre vive en el Sur solo desde que me vine a Santiago y siempre me preocupo de que le pasara algo y no lo pudiera asistir </div>
                <div class="testimonial-info-two"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg" loading="lazy" alt="" class="testimonial-author-image-three">
                  <div>
                    <div class="testimonial-author-name">Marcos Fuenzalida </div>
                    <div>Hijo </div>
                  </div>
                </div>
              </div><img src="images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min.jpg" loading="lazy" srcset="images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-500.jpg 500w, images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-800.jpg 800w, images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-1080.jpg 1080w, images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-1600.jpg 1600w, images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-2000.jpg 2000w, images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-2600.jpg 2600w, images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min-p-3200.jpg 3200w, images/senior-man-with-disability-sitting-in-the-room-2022-02-03-00-22-07-utc-min.jpg 8256w" sizes="(max-width: 479px) 84vw, (max-width: 767px) 89vw, (max-width: 991px) 21vw, 350px" alt="" class="testimonial-image-two">
            </div>
          </div>
        </div>
        <div class="testimonial-slider-arrow w-slider-arrow-left">
          <div class="w-icon-slider-left"></div>
        </div>
        <div class="testimonial-slider-arrow w-slider-arrow-right">
          <div class="w-icon-slider-right"></div>
        </div>
        <div class="testimonial-slider-nav w-slider-nav w-slider-nav-invert w-round"></div>
      </div>
    </div>
  </section>
  <div class="section-2 wf-section">
    <div class="div-block-23">
      <h1 class="heading-13">Preguntas Frecuentes </h1>
      <div data-current="Tab 6" data-easing="ease" data-duration-in="300" data-duration-out="100" class="tabs w-tabs">
        <div class="tabs-menu w-tab-menu">
          <a data-w-tab="Tab 1" class="tab-link-tab-1 w-inline-block w-tab-link">
            <div>Como Reiniciar mi camara </div>
          </a>
          <a data-w-tab="Tab 2" class="w-inline-block w-tab-link">
            <div>Tab 2</div>
          </a>
          <a data-w-tab="Tab 3" class="w-inline-block w-tab-link">
            <div>Tab 3</div>
          </a>
          <a data-w-tab="Tab 4" class="w-inline-block w-tab-link">
            <div>Tab 4</div>
          </a>
          <a data-w-tab="Tab 5" class="w-inline-block w-tab-link">
            <div>Tab 5</div>
          </a>
          <a data-w-tab="Tab 6" class="w-inline-block w-tab-link w--current">
            <div>Como Reiniciar mi camara </div>
          </a>
        </div>
        <div class="tabs-content w-tab-content">
          <div data-w-tab="Tab 1" class="tab-pane-tab-1 w-tab-pane"></div>
          <div data-w-tab="Tab 2" class="tab-pane-tab-2 w-tab-pane"></div>
          <div data-w-tab="Tab 3" class="tab-pane-tab-3 w-tab-pane"></div>
          <div data-w-tab="Tab 4" class="tab-pane-tab-4 w-tab-pane"></div>
          <div data-w-tab="Tab 5" class="tab-pane-tab-5 w-tab-pane"></div>
          <div data-w-tab="Tab 6" class="tab-pane-tab-6 w-tab-pane w--tab-active">
            <div class="videos">
              <div class="div-block">
                <a href="#" class="lightbox-link w-inline-block w-lightbox"><img src="images/13.Como-reiniciar-mi-cámara-ip.png" loading="lazy" width="250" height="120" alt="" class="image-10">
                  <script type="application/json" class="w-json">{
  "items": [
    {
      "url": "https://www.youtube.com/watch?v=UzPhT1HKB9g&list=PLqHAbgSZB6gabRIvlQ1VPczs_5y2l443q&index=13",
      "originalUrl": "https://www.youtube.com/watch?v=UzPhT1HKB9g&list=PLqHAbgSZB6gabRIvlQ1VPczs_5y2l443q&index=13",
      "width": 940,
      "height": 528,
      "thumbnailUrl": "https://i.ytimg.com/vi/UzPhT1HKB9g/hqdefault.jpg",
      "html": "<iframe class=\"embedly-embed\" src=\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FUzPhT1HKB9g%3Flist%3DPLqHAbgSZB6gabRIvlQ1VPczs_5y2l443q&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DUzPhT1HKB9g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FUzPhT1HKB9g%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\" width=\"940\" height=\"528\" scrolling=\"no\" title=\"YouTube embed\" frameborder=\"0\" allow=\"autoplay; fullscreen\" allowfullscreen=\"true\"></iframe>",
      "type": "video"
    }
  ],
  "group": ""
}</script>
                </a>
              </div>
              <div class="text-video">Como reiniciar mi cámara ip</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="pricing-comparison wf-section">
    <div class="containerhtml">
      <div class="pricing-wrapper">
        <div id="w-node-_51240f6a-8a91-ad81-56c4-92e84563de82-3400ac8a" class="pricing-card"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a28a812aad9_placeholder%202.svg" loading="lazy" alt="" class="pricing-image">
          <h2 class="pricing-title">Startup</h2>
          <div class="pricing-subtitle">Starting at</div>
          <div class="pricing-price">$599/mo</div>
          <div class="paragraph-regular margin-bottom-20">Lorem ipsum dolor</div>
          <a href="#" class="button-primary outline-button w-button">Talk to an Expert</a>
          <div class="pricing-divider"></div>
          <ul role="list" class="pricing-feature-list w-list-unstyled">
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
          </ul>
        </div>
        <div id="w-node-_51240f6a-8a91-ad81-56c4-92e84563dea2-3400ac8a" class="pricing-card featured-pricing"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a867e12aade_portfolio%201%20-%20wide.svg" loading="lazy" alt="" class="pricing-image">
          <h2 class="pricing-title">Growth</h2>
          <div class="pricing-subtitle">Starting at</div>
          <div class="pricing-price">$1,499/mo</div>
          <div class="paragraph-regular margin-bottom-20">Lorem ipsum dolor</div>
          <a href="#" class="button-primary w-button">Talk to an Expert</a>
          <div class="pricing-divider"></div>
          <ul role="list" class="pricing-feature-list w-list-unstyled">
            <li>
              <div class="pricing-feature">Etiam sodales ac felis id interdum</div>
            </li>
            <li>
              <div class="pricing-feature">Etiam sodales ac felis id interdum</div>
            </li>
            <li>
              <div class="pricing-feature">Etiam sodales ac felis id interdum</div>
            </li>
            <li>
              <div class="pricing-feature">Etiam sodales ac felis id interdum</div>
            </li>
            <li>
              <div class="pricing-feature">Etiam sodales ac felis id interdum</div>
            </li>
            <li>
              <div class="pricing-feature">Etiam sodales ac felis id interdum</div>
            </li>
            <li>
              <div class="pricing-feature">Etiam sodales ac felis id interdum</div>
            </li>
            <li>
              <div class="pricing-feature">Etiam sodales ac felis id interdum</div>
            </li>
          </ul>
          <div class="pricing-tag">Recommended</div>
        </div>
        <div id="w-node-_51240f6a-8a91-ad81-56c4-92e84563deca-3400ac8a" class="pricing-card"><img src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a55c612aae2_portfolio%202%20-%20wide.svg" loading="lazy" alt="" class="pricing-image">
          <h2 class="pricing-title">Enterprise</h2>
          <div class="pricing-subtitle">Starting at</div>
          <div class="pricing-price">$2,499/mo</div>
          <div class="paragraph-regular margin-bottom-20">Lorem ipsum dolor</div>
          <a href="#" class="button-primary outline-button w-button">Talk to an Expert</a>
          <div class="pricing-divider"></div>
          <ul role="list" class="pricing-feature-list w-list-unstyled">
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
            <li>
              <div class="pricing-feature">Lorem ipsum dolor sit amet</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>

<script>
import {
  getPaymentValue,
  startPaymentPlan,
  getOrderStatusByToken,
} from "../helpers/api/planes";
export default {
  name: "Home",
  components: {},
   data: () => ({
    toPayPlan: {
      plan_id: 1,
      months_paid: 6,
    },
    loading: false,
    requestedStatus: false,
    playing: false,
  }),
    mounted() {
    if (!this.requestedStatus && this.$route.query && this.$route.query.token) {
      this.getOrderStatus(this.$route.query.token);
    }
  },
 methods: {
    async startPayment() {
      const planPayment = await startPaymentPlan(this.toPayPlan);

      if (planPayment.code === 200) {
        if (
          planPayment.flowData &&
          planPayment.flowData.url &&
          planPayment.flowData.token
        ) {
          const payUrl =
            planPayment.flowData.url + "?token=" + planPayment.flowData.token;
          window.location.href = payUrl;
        }
      }
    },
    async getOrderStatus(order_token) {
      this.requestedStatus = true;

      this.loading = true;
      const status = await getOrderStatusByToken({ order_token });
      if (status.code === 200 && status.orderStatus) {
        console.log(status.orderStatus);
        const data = {
          flowOrder: status.orderStatus.flowOrder,
          amount: status.orderStatus.amount,
          status: status.orderStatus.status,
          forma: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.media
            : "",
          fecha: status.orderStatus.paymentData
            ? status.orderStatus.paymentData.date
            : "",
        };
        this.$store.commit("setModalsStates", {
          paymentStatus: { open: true, data },
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/normalize.css";
@import "../../assets/css/webflow.css";
@import "../../assets/css/sosclick-abuelos-boton.webflow.css";

[data-wf-bgvideo-fallback-img] {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  [data-wf-bgvideo-fallback-img] {
    position: absolute;
    z-index: -100;
    display: inline-block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>