const endpoints = {
    login: '/api/auth/login',
    userData: '/api/userData',
    user: '/api/app/user/details',
    videollamada: '/api/calls/videoCall',
    videoEmail: '/api/calls/videoMail',
    speciality: '/api/company/specialities',
    callEvent: '/api/events',
    postFormContac: '/api/attention/contact',
    paisesActivos: '/api/auth/getPaisesActivos',
    regionesActivas: '/api/auth/getRegionesActivas',
    comunasActivas: '/api/auth/getComunasActivas',

    //payment
    getPaymentValue: '/api/app/planes/getToPayData',
    startPaymentPlan: '/api/app/pagos/createPurchaseOrder', // /app/pagos/createPurchaseOrder?product_id=1&qty=1
    getOrderStatusByToken: '/api/auth/getOrderStatusByToken',

    //Registro
    register: '/api/register',
    getToken: '/api/token',

    //SERVICIOS
    getServices: '/api/company/serviceStatus',

    fileUpload: '/api/calls/videoCall/{videoCallID}/publicAttachment',
    getFilePublicUrl: '/api/attachments/'
}

export {endpoints}