<template>
     <div class="footer wf-section">
    <div class="div-block-15">
      <img src="images/logo-natural.svg" loading="lazy" id="w-node-_513aceae-6d54-e40c-8a16-474425a4668a-3400ac8a" width="186" alt="" class="image-11">

        <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-3400ac8a" href="https://www.sosclick.cl/" target="_self" class="footer-link-2">Web SOSClick</a>
        <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-3400ac8a" href="/#planes" class="footer-link-2">Planes</a>
        <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-3400ac8a" href="http://ventasadultomayor.sosclick.cl/" class="footer-link-2" target="_self">SOSClick Adulto Mayor</a>
        <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-3400ac8a" href="http://ventas.iduam.com/" class="footer-link-2" target="_self">Iduam</a>
        <!--a  id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-3400ac8a" href="#" class="footer-link-2">Contacto</a>


      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668b-3400ac8a" href="#" class="footer-link-2">Home</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668d-3400ac8a" href="#" class="footer-link-2">Planes</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a4668f-3400ac8a" href="#" class="footer-link-2">Tutoriales</a>
      <a id="w-node-_513aceae-6d54-e40c-8a16-474425a46691-3400ac8a" href="#" class="footer-link-2">Contacto</a-->
    </div>
    <div class="div-block-13">
      <div class="div-block-14">
        <div class="linea"></div>
      </div>
      <div class="text-block-3">2022 © Naturalphone S.A. Todos los derechos reservados.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>